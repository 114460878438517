const emailValidation = (e, setEmailValidation, setEmailValidationMsg) => {
    const reg = new RegExp(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
        'i'
    );
    if (!reg.test(e.target.value)) {
        setEmailValidation(false);
        setEmailValidationMsg('Вказано некоректний email.');
    } else {
        setEmailValidation(true);
        setEmailValidationMsg(undefined);
    }
};

export default emailValidation;