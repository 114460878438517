import copyText from '../../features/copyText';

//icons
import copyIcon from '../../assets/icons/copy.svg';

import './style.scss';

export default function ApplicationsAmountCard({
    type = 'single',
    color = '',
    title,
    value,
    label,
    otherValues,
    description,
}) {
    if (type === 'multiple') {
        return (
            <div className={`applications-amount-card ${color}`}>
                <h2>{title}</h2>
                <div className='applications-amount-card-data-main'>
                    {value}
                    <button onClick={() => copyText(value)}>
                        <img src={copyIcon} alt='copy icon' />
                    </button>
                </div>

                {otherValues.map((value, index) => {
                    return (
                        <div key={index} className='applications-amount-card-data-minor'>
                            <span>{value.title}:</span> {value.value}
                        </div>
                    );
                })}

                {description && <p>{description} </p>}

                <span>{label}</span>
            </div>
        );
    } else {
        return (
            <div className={`applications-amount-card ${color}`}>
                <h2>{title}</h2>
                <div className='applications-amount-card-data-main'>
                    {value}
                    <button onClick={() => copyText(value)}>
                        <img src={copyIcon} alt='copy icon' />
                    </button>
                </div>
                {description && <p>{description} </p>}
                <span>{label}</span>
            </div>
        );
    }
}
