const phoneValidation = (e, setPhoneValidation, setPhoneValidationMsg) => {
    const reg = new RegExp(/^\d+$/);
    if (e.target.value.length !== 9) {
        setPhoneValidation(false);
        setPhoneValidationMsg('Телефон повинен містити 9 цифр.');
    } else if (!reg.test(e.target.value)) {
        setPhoneValidation(false);
        setPhoneValidationMsg('Телефон повинен містити тільки цифри.');
    } else {
        setPhoneValidation(true);
        setPhoneValidationMsg(undefined);
    }
};

export default phoneValidation;
