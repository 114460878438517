import { useState, useEffect, useRef } from 'react';

import './style.scss';

import arrowDown from '../../../assets/icons/arrow-down.svg';

export default function CustomSelect({
    label,
    value,
    options,
    handler,
    required,
    disabled,
}) {
    const customSelectRef = useRef();

    const [isOptionsShown, setIsOptionsShown] = useState(false);

    useEffect(() => {
        window.addEventListener('click', (e) => {
            const { target } = e;
            if (
                target instanceof Node &&
                !customSelectRef.current?.contains(target)
            ) {
                setIsOptionsShown(false);
            }
        });
    }, []);

    const showOptions = () => {
        setIsOptionsShown(true);
    };

    const setOption = (option) => {
        setIsOptionsShown(false);
        handler(option);
    };

    return (
        <div className='custom-select' ref={customSelectRef}>
            <div className='custom-select-label'>
                {label}
                {required && (
                    <span className='custom-input-label-required'> *</span>
                )}
            </div>
            <div
                className='custom-select-input'
                onClick={() => !disabled && showOptions()}
                data-disabled={disabled ? 'true' : 'false'}
            >
                {value === undefined ? 'Не вказано' : value}
                <span
                    className='custom-select-input-arrow'
                    data-reversed={isOptionsShown ? 'true' : 'false'}
                >
                    <img src={arrowDown} alt='arrow-down' />
                </span>
            </div>
            <div className='custom-select-options' hidden={!isOptionsShown}>
                <div onClick={() => setOption()}>Не вказано</div>
                {options.map((option, index) => {
                    return (
                        <div key={index} onClick={() => setOption(option)}>{option}</div>
                    );
                })}
            </div>
        </div>
    );
}
