import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

//components
import Aside from '../../components/Aside/Aside';
import PageTitle from '../../components/PageTitle/PageTitle';
import Loader from '../../components/Loader/Loader';

//services
import AuthService from '../../services/auth.service';
import authHeader from '../../services/auth-header';

//api
import API from '../../app/api';

//styles
import './style.scss';

export default function UsersPage() {
    const navigate = useNavigate();

    const ROLES = {
        1: 'Користувач',
        2: 'Модератор',
        3: 'Адміністратор'
    }

    //service states
    const [isLoading, setIsLoading] = useState(true);

    //data states
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios
            .get(API.users.readAll, { headers: authHeader() })
            .then((response) => {
                response.data && setUsers(response.data);
                console.log('first', response.data[0]);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(API.users.readAll);
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate('/login');
                    window.location.reload();
                } else {
                    navigate('/error', { state: { error: error.response.data.message } });
                }
            });
    }, [navigate]);

    return (
        <div className='main-wrapper'>
            <Aside />

            <main className='container'>
                <div className='user-page'>
                    <PageTitle pageTitle={'Користувачі'} />

                    <table className='user-page-users-table'>
                        <thead>
                            <tr>
                                <th>Ім'я</th>
                                <th>Пошта</th>
                                <th>Роль</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => {
                                if (user.id === 1) {
                                    return <></>;
                                }
                                return (
                                    <tr key={user.id}>
                                        <td>{user.username}</td>
                                        <td>{user.email}</td>
                                        <td>{ROLES[user.roles.length]}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </main>
            {isLoading && <Loader />}
        </div>
    );
}
