const formatUnicefReport = (data, startDate, endDate) => {
    let ReportingDate = new Date().toDateString();
    let ReportingSitRep =
        startDate.getMonth() === endDate.getMonth() ? startDate.getMonth() + 1 : `${startDate.getMonth() + 1}-${endDate.getMonth() + 1}`;

    const hpdIndicatorUtil = (hpd, role) => {
        hpd = hpd.toLowerCase();

        if (role === "teacher") {
            switch (true) {
                case hpd.includes("minecraft"):
                    return "# of teachers/facilitators in Digital Learning Centers who are trained on using learning through play tools";
                case hpd.includes("дізнайся"):
                    return "# of teachers in primary and secondary schools trained on distance and blended learning techniques";
                case hpd.includes("наздоженемо"):
                    return "# of teachers and educational personnel trained to address learning loss through remedial and catch-up learning";
                default:
                    return "";
            }
        }

        if (role === "student") {
            switch (true) {
                case hpd.includes("дізнайся"):
                    return "# of children benefiting from teachers trained on distance and blended learning techniques";
                case hpd.includes("наздоженемо"):
                    return "# of children benefiting from teachers trained to address learning loss through remedial and catch-up learning";
                default:
                    return "";
            }
        }

        return "";
    };

    const calculateChildrens = (coverage, students, gender = "male") => {
        const genderCoefficients = {
            male: 0.49,
            female: 0.51,
        };

        if (coverage === null && students === null) {
            return 0;
        }

        if (coverage === null) {
            return Math.round(students * genderCoefficients[gender]);
        }

        if (students === null) {
            return Math.round(coverage * genderCoefficients[gender]);
        }

        return Math.round(Math.min(coverage, students) * genderCoefficients[gender]);
    };

    const result = [];

    data.forEach((el) => {
        const school = el.school_id !== null ? el.school_id : el.name;
        result.push({
            name: el.training,
            quantity: 1,
            unit: "trainings",
            vendorNumber: 2500242682,
            deliveredAssistenceTo: "",
            implementingPartner: "OSVITORIA",
            endBeneficiaryType: el.school_id ? "Secondary Education Facility (school)" : "Individuals",
            endBeneficiaryName: school,
            deliveredToEndBeneficiaries: "yes",
            grant: "",
            oblast: "",
            rayon: "",
            hromada: "",
            settlement: "",
            ReportingSitRep,
            ReportingDate,
            Children5Fem: "",
            Children5_9Fem: "",
            Children10_14Fem: "",
            Children15_17Fem: "",
            Children0_17Fem: "",
            Children5Male: "",
            Children5_9Male: "",
            Children10_14Male: "",
            Children15_17Male: "",
            Children0_17Male: "",
            ChildrenTotal: "",
            ChildrenIdpTotal: "",
            ChildrenWithDisab: "",
            adultsFem: el.female === "0" ? "" : el.female,
            adultsMale: el.male === "0" ? "" : el.male,
            adultsTotal: el.total,
            adultsIdpTotal: el.idp === "0" ? "" : el.idp,
            absoluteNumberOfBeneficiaries: el.total,
            beneficiariesUnique: "yes",
            operationalField: "Education",
            hpdIndicator: hpdIndicatorUtil(el.training, "teacher"),
            hacIndicator: "# teachers and educational personnel trained to address learning loss through remedial and catch-up learning",
        });
    });

    data.forEach((el) => {
        const school = el.school_id !== null ? el.school_id : el.name;

        const Children5_9Fem = calculateChildrens(el.primary_school_coverage, el.primary_school_students, "female");
        const Children10_14Fem = calculateChildrens(el.secondary_school_coverage, el.secondary_school_students, "female");
        const Children15_17Fem = calculateChildrens(el.high_school_coverage, el.high_school_students, "female");
        const Children0_17Fem = Children5_9Fem + Children10_14Fem + Children15_17Fem;

        const Children5_9Male = calculateChildrens(el.primary_school_coverage, el.primary_school_students, "male");
        const Children10_14Male = calculateChildrens(el.secondary_school_coverage, el.secondary_school_students, "male");
        const Children15_17Male = calculateChildrens(el.high_school_coverage, el.high_school_students, "male");
        const Children0_17Male = Children5_9Male + Children10_14Male + Children15_17Male;

        const ChildrenTotal = Children0_17Fem + Children0_17Male;

        result.push({
            name: el.training,
            quantity: 1,
            unit: "trainings",
            vendorNumber: 2500242682,
            deliveredAssistenceTo: "",
            implementingPartner: "OSVITORIA",
            endBeneficiaryType: el.school_id ? "Secondary Education Facility (school)" : "Individuals",
            endBeneficiaryName: school,
            deliveredToEndBeneficiaries: "yes",
            grant: "",
            oblast: "",
            rayon: "",
            hromada: "",
            settlement: "",
            ReportingSitRep,
            ReportingDate,
            Children5Fem: "",
            Children5_9Fem,
            Children10_14Fem,
            Children15_17Fem,
            Children0_17Fem,
            Children5Male: "",
            Children5_9Male,
            Children10_14Male,
            Children15_17Male,
            Children0_17Male,
            ChildrenTotal,
            ChildrenIdpTotal: "",
            ChildrenWithDisab: "",
            adultsFem: "",
            adultsMale: "",
            adultsTotal: "",
            adultsIdpTotal: "",
            absoluteNumberOfBeneficiaries: ChildrenTotal,
            beneficiariesUnique: "yes",
            operationalField: "Education",
            hpdIndicator: hpdIndicatorUtil(el.training, "student"),
            hacIndicator: "Other",
        });
    });

    return result;
};

module.exports = formatUnicefReport;
