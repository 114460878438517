//styles
import Aside from '../../components/Aside/Aside';
import PageTitle from '../../components/PageTitle/PageTitle';
import './style.scss';

export default function UnicefCoursesFormPage() {
    return (
        <>
            <div className='main-wrapper'>
                <Aside />

                <main className='container'>

                    <div className='unicef-courses-form-page'>
                        <PageTitle pageTitle='Форма реєстрації на онлайн-курси UNICEF' />
                        <iframe
                            title='UnicefCoursesFormPage'
                            src='https://osv-form.clevio.academy/'
                            frameBorder='0'
                            scrolling='no'
                            height='1100px'
                        ></iframe>
                    </div>
                </main>
            </div>
        </>
    );
}
