import { useState, useEffect, useRef } from "react";

//icons
import triangleDown from "../../../../assets/icons/arrow-down.svg";

//styles
import "./style.scss";

const SelectMultiple = ({ label, options, selectedOptions, handler, required, disabled }) => {
    const customSelectRef = useRef();

    const [isOptionsShown, setIsOptionsShown] = useState(false);

    useEffect(() => {
        window.addEventListener("click", (e) => {
            const { target } = e;
            if (target instanceof Node && !customSelectRef.current?.contains(target)) {
                setIsOptionsShown(false);
            }
        });
    }, []);

    const showOptions = () => {
        setIsOptionsShown(true);
    };

    const setOption = (option) => {
        setIsOptionsShown(false);
        handler(option);
    };

    return (
        <div className='select-multiple' ref={customSelectRef}>
            <div
                className='select-multiple-input'
                onClick={() => !disabled && showOptions()}
                data-disabled={disabled ? "true" : "false"}
                data-focused={isOptionsShown}
            >
                <div className='select-multiple-input-label'>
                    {label}
                    {required && <span className='select-multiple-input-label-required'> *</span>}
                </div>
                Оберіть варіанти
                <span className='select-multiple-input-arrow' data-reversed={isOptionsShown ? "true" : "false"}>
                    <img src={triangleDown} alt='arrow-down' />
                </span>
            </div>
            <div className='select-multiple-options' hidden={!isOptionsShown}>
                {options.map((option, index) => {
                    return (
                        <div key={index} onClick={() => setOption(option)}>
                            {option}
                        </div>
                    );
                })}
                {options.length === 0 && <div>Всі варіанти обрано</div>}
            </div>

            {selectedOptions && selectedOptions.length > 0 && (
                <div className='select-multiple-selected'>
                    {selectedOptions.map((option) => {
                        return (
                            <div key={option}>
                                <span>{option}</span>
                                <button onClick={() => handler(option)}>х</button>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default SelectMultiple;
