import { Link } from 'react-router-dom';

import './style.scss';

export default function Button({ handler, content, disabled = false, type = 'button', link }) {
    if (type === 'button') {
        return (
            <button className='button' onClick={handler} disabled={disabled}>
                {content}
            </button>
        );
    } else {
        return (
            <Link className='button' to={link} disabled={disabled}>
                {content}
            </Link>
        );
    }
}
