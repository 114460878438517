import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

//components
import Aside from '../../components/Aside/Aside';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubtitle from '../../components/UI/PageSubtitle/PageSubtitle';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import Loader from '../../components/Loader/Loader';

//styles
import './style.scss';

import SERVER_URL from '../../constans/server-url';
import parseDate from '../../features/parseDate';

export default function TrainingsControlPage() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [trainings, setTrainings] = useState([]);
    const [trainingId, setTrainingId] = useState(undefined);
    const [trainingName, setTrainingName] = useState(undefined);

    const [training, setTraining] = useState('');
    const [trainingeValidation, setTrainingValidation] = useState(false);
    const [trainingValidationMsg, setTrainingValidationMsg] = useState(undefined);

    useEffect(() => {
        readAllTrainings();
    }, []);

    const saveTraining = () => {
        setIsLoading(true);
        axios
            .post(SERVER_URL + '/api/training/create', {
                name: training,
            })
            .then((response) => {
                readAllTrainings();
                setIsLoading(false);
            })
            .catch((error) => {
                navigate('/error', { state: { error: error.response.data.message } });
            });
    };

    const readAllTrainings = () => {
        axios
            .get(SERVER_URL + '/api/training/read-all')
            .then((response) => {
                const { data } = response;
                setTrainings(data);
                setIsLoading(false);
            })
            .catch((error) => {
                navigate('/error', { state: { error: error.response.data.message } });
            });
    };

    const deleteTraining = () => {
        const id = trainingId;
        id &&
            axios
                .post(SERVER_URL + '/api/training/delete', {
                    id: id,
                })
                .then((response) => {
                    closeModal();
                    readAllTrainings();
                })
                .catch((error) => {
                    navigate('/error', { state: { error: error.response.data.message } });
                });
    };

    const modalHandler = (id, name) => {
        setIsModalOpen(true);
        setTrainingId(id);
        setTrainingName(name);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTrainingId(undefined);
        setTrainingName(undefined);
    };

    const trainingHandler = (e) => {
        setTraining(e.target.value);
    };

    const trainingValidationHandler = (e) => {
        const value = e.target.value;

        if (value.length < 2) {
            setTrainingValidation(false);
            setTrainingValidationMsg('Поле повинно мати як мінімум дві літери.');
        } else {
            setTrainingValidation(true);
            setTrainingValidationMsg(undefined);
        }
    };

    return (
        <>
            <div className='main-wrapper'>
                <Aside />

                <main className='container'>
                    <div className='trainings-control-page'>
                        <PageTitle pageTitle='Тренінги' />
                        <PageSubtitle pageSubtitle='Додати тренінг' />

                        <div className='trainings-control-page-create'>
                            <div>
                                <Input
                                    label='Назва тренінга'
                                    value={training}
                                    handler={trainingHandler}
                                    validationHandler={trainingValidationHandler}
                                    validation={trainingeValidation}
                                    validationMsg={trainingValidationMsg}
                                    setValidationMsg={setTrainingValidationMsg}
                                    setValidation={setTrainingValidation}
                                    required={true}
                                />
                            </div>

                            <div className='coaches-control-page-control-button'>
                                <Button
                                    content='Додати'
                                    handler={saveTraining}
                                    disabled={training && trainingeValidation ? false : true}
                                />
                            </div>
                        </div>

                        <PageSubtitle pageSubtitle='Управління тренінгами' />
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Назва</th>
                                    <th>Додано</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {trainings.map((training, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{training.id}</td>
                                            <td>{training.name}</td>
                                            <td>{parseDate(training.createdAt)}</td>
                                            <td>
                                                <Button
                                                    content='Видалити'
                                                    handler={() => modalHandler(training.id, training.name)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </main>
            </div>
            {isLoading && <Loader />}
            {isModalOpen && (
                <Modal
                    title='Ви впевнені?'
                    message={`Видалення тренінгу "${trainingName}" видалить його у всіх тренерів.`}
                    handler={deleteTraining}
                    buttonText='Видалити'
                    closeHandler={closeModal}
                />
            )}
        </>
    );
}
