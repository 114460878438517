//styles
import './style.scss';

export default function NotFoundPage() {
    return (
        <main className='container'>
            <div className='not-found-page'>
                <div className='not-found-page-wrapper'>
                    <h1>
                        <span>4</span>
                        <span>0</span>
                        <span>4</span>
                    </h1>
                    <h2>Сторінку не знайдено</h2>
                </div>
            </div>
        </main>
    );
}
