import ReactDatePicker, { registerLocale } from "react-datepicker";
import uk from "date-fns/locale/uk";

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

export default function InputDate({ label, value, handler, required }) {
    registerLocale("uk", uk);

    return (
        <div className='input-date'>
            <div className='input-date-label'>
                {label}
                {required && <span className='input-date-label-required'> *</span>}
            </div>
            <ReactDatePicker
                locale='uk'
                dateFormat='dd.MM.yyyy'
                selected={value}
                onChange={(value) => handler(value)}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                popperPlacement='bottom'
            />
        </div>
    );
}
