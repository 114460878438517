import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

//components
import Aside from "../../components/Aside/Aside";
import PageTitle from "../../components/PageTitle/PageTitle";
import Pagination from "../../components/Pagination/Pagination";
import Loader from "../../components/Loader/Loader";
import ApplicationsAmountCard from "../../components/ApplicationsAmountCard/ApplicationsAmountCard";
import CustomDateInput from "../../components/UI/CustomDateInput/CustomDateInput";
import SelectWithSearch from "../../components/UI/admin/SelectWithSearch/SelectWithSearch";
import Select from "../../components/UI/admin/Select/Select";
import InputDate from "../../components/UI/admin/InputDate/InputDate";

//api
import API from "../../app/api";

//services
import AuthService from "../../services/auth.service";
import authHeader from "../../services/auth-header";
import parseDate from "../../features/parseDate";

//styles
import "./style.scss";
import formatUnicefReport from "../../features/formatUnicefReport";
import Button from "../../components/UI/Button/Button";
import SelectMultiple from "../../components/UI/admin/SelectMultiple/SelectMultiple";
import formatUnicefNarrativeReport from "../../features/formatUnicefNarrativeReport";
import downloadDataAsCSVFile from "../../features/downloadDataAsCSVFile";

//constants
const PAGE_TITLE = "Реєстрації на офлайн-тренінги UNICEF";

const ORDER_OPTIONS = { "Номером учасника": "id", "Іменем учасника": "first_name", "Прізвищем учасника": "last_name" };

const Applications = ({ applications, deleteApplicationHandler }) => {
    if (applications && applications.length > 0) {
        return (
            <>
                <table className='unicef-applications-records'>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>ID школи</th>
                            <th>Ім'я учасника</th>
                            <th>Прізвище учасника</th>
                            <th>Тренер</th>
                            <th>Тренінг</th>
                            <th>Дата</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.map((application) => {
                            return (
                                <tr key={application.id}>
                                    <th>{application.id}</th>
                                    <th>{application.school_id || "-"}</th>
                                    <th>{application.first_name}</th>
                                    <th>{application.last_name}</th>
                                    <th>{application.coach}</th>
                                    <th>{application.training}</th>
                                    <th>{parseDate(application.date).split(",")[0]}</th>
                                    <th>
                                        <Button content='Видалити' handler={() => deleteApplicationHandler(application.id)} />
                                    </th>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    } else {
        return <div className='unicef-applications-records-not-found'>За цим критерієм реєстрацій не знайдено!</div>;
    }
};

const ReportExportModal = ({ handler, closeHandler, startValue, startValueHandler, endValue, endValueHandler }) => {
    return (
        <div className='report-modal'>
            <div className='report-modal-window'>
                <button className='report-modal-window-close-button' onClick={closeHandler}>
                    x
                </button>
                <h2>Оберіть період звітності:</h2>
                <CustomDateInput label='Від:' value={startValue} handler={startValueHandler} />
                <CustomDateInput label='До:' value={endValue} handler={endValueHandler} />
                {startValue > endValue && <div className='report-modal-window-error'>Невірний діапазон дат!</div>}
                <button className='report-modal-window-submit-button' onClick={() => handler(false)} disabled={startValue > endValue ? true : ""}>
                    Завантажити (унікальні за весь час)
                </button>
                <button className='report-modal-window-submit-button' onClick={() => handler(true)} disabled={startValue > endValue ? true : ""}>
                    Завантажити (унікальні за вказаний період)
                </button>
            </div>
        </div>
    );
};

export default function UnicefApplicationsPage() {
    const navigate = useNavigate();

    const a = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [isReportExportModalOpen, setIsReportExportModalOpen] = useState(false);

    const [limit, setLimit] = useState(10);
    const [order, setOrder] = useState("id");
    const [orderTitle, setOrderTitle] = useState("Номером учасника");

    const [applications, setApplications] = useState([]);
    const [applicationsAmount, setApplicationsAmount] = useState(null);

    const [trainings, setTrainings] = useState([]);

    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);

    const [reportStartDate, setReportStartDatee] = useState(new Date());
    const [reportEndDate, setReportEndDate] = useState(new Date());

    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);

    const [coaches, setCoaches] = useState([]);
    const [coachesData, setCoachesData] = useState({});
    const [coach, setCoach] = useState(undefined);

    const [trainingsList, setTrainingsList] = useState([]);

    const [selectedTrainings, setSelectedTrainings] = useState([]);

    useEffect(() => {
        axios
            .post(API.application.uot.readAllAmount, { selectedTrainings, coach, startDate, endDate }, { headers: authHeader() })
            .then((response) => {
                response.data &&
                    (response.data.amount || response.data.amount === 0) &&
                    (setApplicationsAmount(response.data.amount) || setPages(Math.ceil(response.data.amount / limit)));
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    }, [limit, selectedTrainings, coach, startDate, endDate, navigate]);

    useEffect(() => {
        axios
            .get(API.application.uot.readAllAmountByTraining, { headers: authHeader() })
            .then((response) => {
                let { data } = response;
                let total = data.reduce((prev, cur) => {
                    return prev + +cur.amount;
                }, 0);
                setTrainings([{ training: "Всього", amount: total }, ...data]);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    }, [navigate]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .post(
                API.application.uot.readAll,
                { limit: limit, offset: (page - 1) * limit, order, coach, selectedTrainings, startDate, endDate },
                { headers: authHeader() }
            )
            .then((response) => {
                response.data && setApplications(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    }, [limit, page, order, coach, selectedTrainings, startDate, endDate, navigate]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(API.coach.readAll)
            .then((response) => {
                let { data } = response;
                setCoaches(data.map((coach) => coach.name));

                let dat = {};
                data.forEach((coach) => (dat[coach.name] = coach.trainings.map((i) => i.name)));

                setCoachesData(dat);
                setIsLoading(false);
            })
            .catch((error) => {
                navigate("/error", { state: { error: error.response.data.message } });
            });
    }, [navigate]);

    useEffect(() => {
        if (coach) {
            setTrainingsList(coachesData[coach]);
        } else {
            setTrainingsList([]);
        }
        setSelectedTrainings([]);
        setPage(1);
    }, [coach, coachesData]);

    useEffect(() => {
        if (applications.length > 0 && applicationsAmount) {
            setIsLoading(false);
        }
    }, [applications, applicationsAmount]);

    const updatePage = (value) => {
        a.current.scrollIntoView();
        setPage(value);
    };

    const downloadApplications = () => {
        axios
            .get(API.application.uot.readAllDownload, { headers: authHeader(), responseType: "blob" })
            .then((response) => {
                const fileName = "file.csv";
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement("a");

                anchorElement.href = href;
                anchorElement.download = fileName;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const downloadReportCSV = (periodUnique = false) => {
        setIsLoading(true);

        axios
            .post(
                API.application.uot.readAllForReporting,
                {
                    startDate: `${reportStartDate.getFullYear()}-${reportStartDate.getMonth() + 1}-${reportStartDate.getDate()}`,
                    endDate: `${reportEndDate.getFullYear()}-${reportEndDate.getMonth() + 1}-${reportEndDate.getDate()}`,
                    periodUnique,
                },
                { headers: authHeader() }
            )
            .then((response) => {
                const { data } = response;
                const startDate = reportStartDate.toLocaleDateString();
                const endDate = reportEndDate.toLocaleDateString();

                const unicefReportData = formatUnicefReport(data, reportStartDate, reportEndDate);
                downloadDataAsCSVFile(unicefReportData, `Unicef Report Data (${startDate} - ${endDate}).csv`);

                const narrative = formatUnicefNarrativeReport(data);
                downloadDataAsCSVFile(narrative, `Unicef Narrative Report Data (${startDate} - ${endDate}).csv`);

                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const reportExportModalHandler = (periodUnique) => {
        downloadReportCSV(periodUnique);
    };

    const reportExportModalCloseHandler = () => {
        setIsReportExportModalOpen(false);
        setStartDate(new Date());
        setEndDate(new Date());
    };

    const trainingsHandler = (value) => {
        if (trainingsList.includes(value)) {
            let newTrainings = [...trainingsList];
            const index = newTrainings.indexOf(value);
            newTrainings.splice(index, 1);

            setTrainingsList(newTrainings);
            setSelectedTrainings((selectedTrainings) => [...selectedTrainings, value]);
        } else {
            const newSelectedTrainings = [...selectedTrainings];
            const index = newSelectedTrainings.indexOf(value);
            newSelectedTrainings.splice(index, 1);

            setTrainingsList((trainings) => [...trainings, value]);
            setSelectedTrainings(newSelectedTrainings);
        }
    };

    const deleteApplicationHandler = (id) => {
        setIsLoading(true);
        axios
            .post(API.application.uot.deleteApplicationById, { id }, { headers: authHeader() })
            .then((response) => {
                updateApplications();
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const updateApplications = () => {
        axios
            .post(
                API.application.uot.readAll,
                { limit: limit, offset: (page - 1) * limit, coach, selectedTrainings, startDate, endDate },
                { headers: authHeader() }
            )
            .then((response) => {
                response.data && setApplications(response.data);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const limitHandler = (limit) => {
        setPage(1);
        setLimit(limit);
    };

    const orderHanler = (value) => {
        setOrderTitle(value);
        setOrder(ORDER_OPTIONS[value]);
    };

    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE}</title>
            </Helmet>
            <div className='main-wrapper'>
                <Aside />

                <main className='container'>
                    <div className='unicef-applications' ref={a}>
                        <PageTitle pageTitle={PAGE_TITLE} />

                        <div className='unicef-applications-dashboard'>
                            {trainings.map((item, index) => {
                                return (
                                    <ApplicationsAmountCard
                                        key={index}
                                        title={item.training}
                                        value={item.amount}
                                        label={item.training === "Всього" ? "Σ" : index}
                                    />
                                );
                            })}
                        </div>

                        <div className='unicef-applications-export'>
                            <Link to={"/trainings/unicef"}>Переглянути форму</Link>
                            <button onClick={downloadApplications}>Експортувати всі дані</button>
                            <button onClick={() => setIsReportExportModalOpen(true)}>Експортувати дані для звіту UNICEF</button>
                        </div>

                        <SelectWithSearch label='Тренер' value={coach} setValue={setCoach} options={coaches} notFoundText='Тренера не знайдено' />

                        <SelectMultiple
                            label='Тренінги'
                            options={trainingsList}
                            selectedOptions={selectedTrainings}
                            handler={trainingsHandler}
                            disabled={!coach}
                        />

                        <div className='unicef-applications-filter'>
                            <div>
                                <InputDate label='Від' value={startDate} handler={setStartDate} />
                                <InputDate label='До' value={endDate} handler={setEndDate} />
                            </div>
                        </div>

                        <Select
                            label='Сортувати результати за'
                            value={orderTitle}
                            undefinedOption={false}
                            options={Object.keys(ORDER_OPTIONS)}
                            handler={orderHanler}
                        />

                        <div className='unicef-applications-limit'>
                            <div>
                                Знайдено записів: <span>{applicationsAmount}</span>
                            </div>
                            <div>
                                <p>Кількість записів на сторінці:</p>

                                {[10, 20, 50, 100, 200].map((limitItem, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            content={limitItem}
                                            handler={() => {
                                                limitHandler(limitItem);
                                            }}
                                            disabled={limit === limitItem}
                                        />
                                    );
                                })}
                            </div>
                        </div>

                        <div>
                            <Applications applications={applications} deleteApplicationHandler={deleteApplicationHandler} />
                        </div>

                        <Pagination page={page} pages={Array.from(Array(pages).keys())} handler={updatePage} />
                    </div>
                </main>
                {isReportExportModalOpen && (
                    <ReportExportModal
                        handler={reportExportModalHandler}
                        closeHandler={reportExportModalCloseHandler}
                        startValue={reportStartDate}
                        startValueHandler={setReportStartDatee}
                        endValue={reportEndDate}
                        endValueHandler={setReportEndDate}
                    />
                )}
                {isLoading && <Loader />}
            </div>
        </>
    );
}
