import SERVER_URL from '../constans/server-url';

const API = {
    users: {
        readAll: SERVER_URL + '/api/user/read-all',
    },

    application: {
        uot: {
            create: SERVER_URL + '/api/application/uot/create',
            readAll: SERVER_URL + '/api/application/uot/read-all',
            readAllUser: SERVER_URL + '/api/application/uot/read-user-applications',
            readAllAmount: SERVER_URL + '/api/application/uot/read-all-amount',
            readAllAmountByTraining: SERVER_URL + '/api/application/uot/read-all-amount-by-training',
            readAllDownload: SERVER_URL + '/api/application/uot/read-all-download',
            readAllForReporting: SERVER_URL + '/api/application/uot/read-all-for-reporting',
            deleteApplicationById: SERVER_URL + '/api/application/uot/delete-by-id',
        },
        uoc: {
            readAllDownload: SERVER_URL + '/api/application/uoc/read-all-download',
        },
    },

    coach: {
        readAll: SERVER_URL + '/api/coach/read-all',
    },

    training: {
        readAll: SERVER_URL + '/api/training/read-all',
    },

    school: {
        readRegions: SERVER_URL + '/api/school/read-regions',
        readDistrict: SERVER_URL + '/api/school/read-districts',
        readCommunities: SERVER_URL + '/api/school/read-communities',
        readCities: SERVER_URL + '/api/school/read-cities',
        readSchoolNames: SERVER_URL + '/api/school/read-school-names',
    },
};

export default API;
