import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

//states
import { selectCurrentUser, selectAdminRole } from '../../features/authSlice';

//styles
import './style.scss';

import mainLogo from '../../assets/img/logos/main-logo.svg';
import userIcon from '../../assets/icons/user.svg';

const LoginControl = ({ isCurrentUser, handler }) => {
    return isCurrentUser ? (
        <>
            {/* <a className='btn nav-btn nav-btn-logout' href='/login' onClick={handler}>
            Вихід
        </a> */}
        </>
    ) : (
        <NavLink className='btn nav-btn nav-btn-login' to={'/login'}>
            {' '}
            Вхід{' '}
        </NavLink>
    );
};

export default function Nav({ logOut }) {
    const currentUser = useSelector(selectCurrentUser);
    const hasAdminRole = useSelector(selectAdminRole);

    return (
        <>
            {currentUser && (
                <nav className='nav'>
                    <div className='container'>
                        <div className='nav-wrapper'>
                            <div className='nav-logo'>
                                <Link to={'/'}>
                                    <img src={mainLogo} alt='main logo' />
                                </Link>
                            </div>

                            <div className='nav-login-control'>
                                <div className='nav-login-control-icon'>
                                    <img src={userIcon} alt='User Icon' />
                                    <div className='nav-login-control-icon-logout'>
                                        <a href='/login' onClick={logOut}>
                                            {' '}
                                            <span></span>
                                        </a>
                                    </div>
                                </div>
                                <Link to={'/profile'} className='nav-login-control-info'>
                                    <div>{currentUser.username}</div>
                                    <div>{currentUser.email}</div>
                                </Link>

                                <LoginControl isCurrentUser={currentUser} handler={logOut} />
                            </div>
                        </div>
                    </div>
                </nav>
            )}
        </>
    );
}
