import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { parse } from 'papaparse';

//components
import Aside from '../../components/Aside/Aside';
import PageTitle from '../../components/PageTitle/PageTitle';
import Button from '../../components/UI/Button/Button';
import Loader from '../../components/Loader/Loader';
import ApplicationsAmountCard from '../../components/ApplicationsAmountCard/ApplicationsAmountCard';

//api
import API from '../../app/api';

//services
import AuthService from '../../services/auth.service';
import authHeader from '../../services/auth-header';

//styles
import './style.scss';

//local constants
const PAGE_TITLE = 'Реєстрації на онлайн-курси UNICEF';
const COURSES_EXEPTIONS = ['undefined', 'null', 'none', 'Demo test'];

export default function UnicefCoursesApplicationsPage() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [courses, setCourses] = useState([]);

    const [csv, setCsv] = useState([]);
    const [amount, setAmount] = useState(100);

    const downloadApplications = () => {
        axios
            .get(API.application.uoc.readAllDownload, { headers: authHeader() })
            .then((response) => {
                const fileName = 'file.csv';
                const href = response.data.url;
                console.log(response);

                const anchorElement = document.createElement('a');

                anchorElement.href = href;
                anchorElement.download = fileName;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate('/login');
                    window.location.reload();
                } else {
                    navigate('/error', { state: { error: error.response.data.message } });
                }
            });
    };

    const parseCsv = () => {
        setIsLoading(true);
        axios
            .get(API.application.uoc.readAllDownload, { headers: authHeader() })
            .then((response) => {
                parse(response.data.url, {
                    download: true,
                    header: true,
                    complete: function (results) {
                        setCsv(results.data);

                        let courses = { Всього: results.data.length };
                        results.data.forEach((row) => {
                            if (COURSES_EXEPTIONS.includes(row['Назва курсу'])) {
                                return;
                            }

                            if (Object.keys(courses).includes(row['Назва курсу'])) {
                                courses[row['Назва курсу']] = courses[row['Назва курсу']] + 1;
                            } else {
                                courses[row['Назва курсу']] = 1;
                            }
                        });

                        setCourses(courses);
                        setIsLoading(false);
                    },
                });
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate('/login');
                    window.location.reload();
                } else {
                    navigate('/error', { state: { error: error.response.data.message } });
                }
            });
    };

    const showMore = () => {
        setAmount(prev => prev + 20);
    }

    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE}</title>
            </Helmet>

            <div className='main-wrapper'>
                <Aside />

                <main className='container'>
                    <div className='unicef-courses-applications-page'>
                        <PageTitle pageTitle={PAGE_TITLE} />

                        <div className='unicef-applications-dashboard'>
                            {Object.keys(courses).length > 0 &&
                                Object.keys(courses).map((course, index) => {
                                    return (
                                        <ApplicationsAmountCard
                                            key={index}
                                            title={course}
                                            value={courses[course]}
                                            label={index + 1}
                                        />
                                    );
                                })}
                        </div>

                        <div className='unicef-courses-applications-page-control'>
                            <Button content='Переглянути форму' type='link' link='/courses/unicef' />
                            <Button content='Експортувати в .csv' handler={downloadApplications} />
                            {csv.length > 0 && <Button content='Оновити дані' handler={parseCsv} />}
                        </div>

                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>№</th>
                                        <th>ID навч. закладу</th>
                                        <th>Студент</th>
                                        <th>Курс</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {csv.slice(0, amount).map((row, index) => {
                                        if (!COURSES_EXEPTIONS.includes(row['Назва курсу'])) {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {row['ID Навчального закладу'] === 'none'
                                                            ? 'не вказано'
                                                            : row['ID Навчального закладу']}
                                                    </td>
                                                    <td>{`${row['Імʼя']} ${row['Прізвище']}`}</td>
                                                    <td>
                                                        {row['Назва курсу'] === 'undefined'
                                                            ? 'не вказано'
                                                            : row['Назва курсу']}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                            <div className='unicef-courses-applications-page-csv-control'>
                                {csv.length === 0 ? (
                                    <Button content='Відобразити дані' handler={parseCsv} />
                                ) : (
                                    <Button content='Показати більше' handler={showMore} />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {isLoading && <Loader />}
        </>
    );
}
