import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

//components
import Aside from '../../components/Aside/Aside';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubtitle from '../../components/UI/PageSubtitle/PageSubtitle';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import ToggleSwitch from '../../components/UI/ToggleSwitch/ToggleSwitch';
import Loader from '../../components/Loader/Loader';

//styles
import './style.scss';

import SERVER_URL from '../../constans/server-url';
import parseDate from '../../features/parseDate';

import emailValidationSer from '../../features/emailValidation';
import phoneValidationSer from '../../features/phoneValidation';

const UpdateModal = ({ selectedCoach, closeHandler, trainings, onToggle }) => {
    const [trainings1, setTrainings1] = useState([...selectedCoach.trainings]);

    const handler = (training) => {
        const coachTrainings = trainings1.map((i) => i.id);

        if (coachTrainings.includes(training.id)) {
            setTrainings1((prev) => prev.filter((i) => i.id !== training.id));
        } else {
            setTrainings1((prev) => [...prev, training]);
        }
    };

    const submit = () => {
        onToggle(
            selectedCoach.id,
            trainings1.map((i) => i.id)
        );
    };

    return (
        <div className='update-modal'>
            <div className='update-modal-window'>
                <button className='update-modal-window-close-button' onClick={closeHandler}>
                    x
                </button>
                <div className='update-modal-window-coach'>{selectedCoach.name}</div>
                <div className='update-modal-window-trainings'>
                    {trainings.length > 0 &&
                        trainings.map((training, index) => {
                            return (
                                <div key={index}>
                                    <div>{training.name}</div>
                                    <div>
                                        <ToggleSwitch
                                            isToggled={trainings1.map((i) => i.id).includes(training.id)}
                                            onToggle={() => handler(training)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <Button content={'Зберегти'} handler={submit} />
            </div>
        </div>
    );
};

export default function CoachesControlPage() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

    const [selectedCoach, setSelectedCoach] = useState(undefined);

    const [fullName, setFullName] = useState('');
    const [fullNameValidation, setFullNameValidation] = useState(false);
    const [fullNameValidationMsg, setFullNameValidationMsg] = useState(undefined);

    const [email, setEmail] = useState('');
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailValidationMsg, setEmailValidationMsg] = useState(undefined);

    const [phone, setPhone] = useState('');
    const [phoneValidation, setPhoneValidation] = useState(false);
    const [phoneValidationMsg, setPhoneValidationMsg] = useState(undefined);

    const [trainings, setTrainings] = useState([]);
    const [newCoachTrainings, setNewCoachTrainings] = useState([]);

    const [coaches, setCoaches] = useState([]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/training/read-all').then((response) => {
            if (response.data) {
                const { data } = response;

                setTrainings(
                    data.map((i) => {
                        let a = {};
                        a.id = i.id;
                        a.name = i.name;
                        return a;
                    })
                );
            }
        });
    }, []);

    useEffect(() => {
        readAllCoaches();
    }, []);

    const readAllCoaches = () => {
        axios
            .get(SERVER_URL + '/api/coach/read-all')
            .then((response) => {
                if (response.data) {
                    const { data } = response;
                    setCoaches(data);
                }
            })
            .catch((error) => {
                navigate('/error', { state: { error: error.response.data.message } });
            });
    };

    const fullNameValidationHandler = (e) => {
        const value = e.target.value;
        const reg = new RegExp(/^[АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЬьЮюЯя'"`-\s]+$/, 'g');

        if (value.length < 2) {
            setFullNameValidation(false);
            setFullNameValidationMsg('Поле повинно мати як мінімум дві літери.');
        } else if (!reg.test(value)) {
            setFullNameValidation(false);
            setFullNameValidationMsg('Поле повинно мати тільки українські літери.');
        } else {
            setFullNameValidation(true);
            setFullNameValidationMsg(undefined);
        }
    };

    const fullNameHandler = (e) => {
        setFullName(e.target.value);
    };

    const saveCoach = () => {
        axios
            .post(SERVER_URL + '/api/coach/create', {
                name: fullName,
                email: email,
                phone: '+380' + phone,
                trainings: newCoachTrainings.map((coach) => coach.id),
            })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                navigate('/error', { state: { error: error.response.data.message } });
            });
    };

    const updateCoach = (id, trainings) => {
        setIsLoading(true);
        axios
            .post(SERVER_URL + '/api/coach/update', {
                id,
                trainings,
            })
            .then((response) => {
                readAllCoaches();
                setIsLoading(false);
            })
            .catch((error) => {
                navigate('/error', { state: { error: error.response.data.message } });
            });
    };

    const deleteCoach = (id) => {
        axios
            .post(SERVER_URL + '/api/coach/delete', { id })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                navigate('/error', { state: { error: error.response.data.message } });
            });
    };

    const openCoachUpdateModal = (coach) => {
        setSelectedCoach(coach);
        setIsUpdateModalOpen(true);
    };

    const closeCoachUpdateModal = () => {
        setSelectedCoach(undefined);
        setIsUpdateModalOpen(false);
    };

    const emailHandler = (e) => setEmail(e.target.value);
    const emailValidationHandler = (e) => emailValidationSer(e, setEmailValidation, setEmailValidationMsg);

    const phoneHandler = (e) => setPhone(e.target.value);
    const phoneValidationHandler = (e) => phoneValidationSer(e, setPhoneValidation, setPhoneValidationMsg);

    const handler = (training) => {
        const coachTrainings = newCoachTrainings.map((i) => i.id);

        if (coachTrainings.includes(training.id)) {
            setNewCoachTrainings((prev) => prev.filter((i) => i.id !== training.id));
        } else {
            setNewCoachTrainings((prev) => [...prev, training]);
        }
    };

    return (
        <>
            <div className='main-wrapper'>
                <Aside />

                <main className='container'>
                    <div className='coaches-control-page'>
                        <PageTitle pageTitle='Тренери' />

                        <PageSubtitle pageSubtitle='Додати тренера' />

                        <div className='coaches-control-page-control'>
                            <div className='coaches-control-page-control-group'>
                                <Input
                                    label={"Ім'я та прізвище"}
                                    value={fullName}
                                    handler={fullNameHandler}
                                    validationHandler={fullNameValidationHandler}
                                    validation={fullNameValidation}
                                    validationMsg={fullNameValidationMsg}
                                    setValidationMsg={setFullNameValidationMsg}
                                    setValidation={setFullNameValidation}
                                    required={true}
                                />

                                <Input
                                    label={'Email'}
                                    value={email}
                                    handler={emailHandler}
                                    validationHandler={emailValidationHandler}
                                    validation={emailValidation}
                                    validationMsg={emailValidationMsg}
                                    setValidationMsg={setEmailValidationMsg}
                                    setValidation={setEmailValidation}
                                    required={true}
                                />

                                <Input
                                    label={'Телефон'}
                                    value={phone}
                                    handler={phoneHandler}
                                    validationHandler={phoneValidationHandler}
                                    validation={phoneValidation}
                                    validationMsg={phoneValidationMsg}
                                    setValidationMsg={setPhoneValidationMsg}
                                    setValidation={setPhoneValidation}
                                    required={true}
                                    type={'phone'}
                                />
                            </div>
                            <div>
                                <div className='coaches-control-page-coach-trainings'>
                                <h3>Тренінги</h3>
                                {trainings.length ===0 && <h4>Створіть хоча б один тренінг, щоб додати тренера!</h4>}
                                    {trainings.length > 0 &&
                                        trainings.map((training, index) => {
                                            return (
                                                <div key={index}>
                                                    <div>{training.name}</div>
                                                    <div>
                                                        <ToggleSwitch
                                                            isToggled={newCoachTrainings
                                                                .map((i) => i.id)
                                                                .includes(training.id)}
                                                            onToggle={() => handler(training)}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className='coaches-control-page-control-button'>
                                <Button
                                    content='Додати'
                                    handler={saveCoach}
                                    disabled={
                                        fullName &&
                                        fullNameValidation &&
                                        email &&
                                        emailValidation &&
                                        phone &&
                                        phoneValidation &&
                                        newCoachTrainings.length > 0
                                            ? false
                                            : true
                                    }
                                />
                            </div>
                        </div>

                        <PageSubtitle pageSubtitle='Управління тренерами' />

                        <table className='coaches'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Ім'я</th>
                                    <th>Email</th>
                                    <th>Телефон</th>
                                    <th>Додано</th>
                                    <th>Тренінги</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {coaches.map((coach, index) => {
                                    return (
                                        <tr key={index} className='coaches-coach'>
                                            <td>{coach.id}</td>
                                            <td>{coach.name}</td>
                                            <td>{coach.email}</td>
                                            <td>{coach.phone}</td>
                                            <td>{parseDate(coach.createdAt)}</td>
                                            <td className='coaches-coach-trainings'>
                                                {coach.trainings.length > 0
                                                    ? coach.trainings.map((training, index) => {
                                                          return <span key={index}>{training.name}</span>;
                                                      })
                                                    : 'Не додано'}
                                            </td>
                                            <td>
                                                <Button content='Оновити' handler={() => openCoachUpdateModal(coach)} />
                                            </td>
                                            <td>
                                                <Button content='Видалити' handler={() => deleteCoach(coach.id)} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </main>
            </div>
            {isLoading && <Loader />}
            {isUpdateModalOpen && selectedCoach && trainings && (
                <UpdateModal
                    selectedCoach={selectedCoach}
                    closeHandler={closeCoachUpdateModal}
                    trainings={trainings}
                    onToggle={updateCoach}
                />
            )}
        </>
    );
}
