import { combineReducers, createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userIsLogin: (state) => {
        state.value = true;
    },
    userIsLogout: (state) => {
        state.value = false;
    }
  },
}); 

export const { userIsLogin, userIsLogout } = authSlice.actions;

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {value: undefined},
  reducers: {
    setCurrentUserR: (state, action) => {
        state.value = action.payload;
    },
    unsetCurrentUserR: (state) => {
        state.value = undefined;
    }
  },
});
export const { setCurrentUserR, unsetCurrentUserR } = currentUserSlice.actions;
export const selectCurrentUser = (state) => state.currentUser.value;


export const moderatorRoleSlice = createSlice({
  name: 'moderatorRole',
  initialState: {value: false},
  reducers: {
    setModeratorRole: (state) => {
        state.value = true;
    },
    unsetModeratorRole: (state) => {
        state.value = false;
    }
  },
});
export const { setModeratorRole, unsetModeratorRole } = moderatorRoleSlice.actions;
export const selectModeratorRole = (state) => state.moderatorRole.value;

export const adminRoleSlice = createSlice({
  name: 'adminRole',
  initialState: {value: false},
  reducers: {
    setAdminRole: (state) => {
        state.value = true;
    },
    unsetAdminRole: (state) => {
        state.value = false;
    }
  },
});
export const { setAdminRole, unsetAdminRole } = adminRoleSlice.actions;
export const selectAdminRole = (state) => state.adminRole.value;



export default combineReducers({
  auth: authSlice.reducer,
  currentUser: currentUserSlice.reducer,
  moderatorRole: moderatorRoleSlice.reducer,
  adminRole: adminRoleSlice.reducer
});