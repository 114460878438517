import { useRef, useEffect, useState } from "react";

//styles
import "./style.scss";

export default function SelectWithSearch({ label, value, setValue, options, required, disabled, notFoundText = "Не знайдено" }) {
    const searchRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const [searchOptions, setSearchOptions] = useState(options);
    const [search, setSearch] = useState("");

    useEffect(() => {
        window.addEventListener("click", (e) => {
            const { target } = e;
            if (target instanceof Node && !searchRef.current?.contains(target)) {
                setIsOpen(false);
            }
        });
    }, []);

    const optionsHandler = (i) => {
        const value = i;
        if (value === "undefined") {
            setValue(undefined);
            return;
        } else {
            setValue(value);

            setSearch(value);
            setSearchOptions([value]);
        }
    };

    const searchHandler = (e) => {
        let value = e.target.value;
        setSearch(value);
        let searchQuery = value.toLowerCase();
        let results = options.filter((i) => {
            return i.toLowerCase().includes(searchQuery);
        });

        if (results.length !== 0) {
            setSearchOptions(results);
        } else {
            setSearchOptions([notFoundText]);
        }
    };

    const searchInputChangeHandler = (e) => {
        searchHandler(e);
    };

    const focusHandler = () => {
        setSearch("");
        setSearchOptions([...options]);
        setIsOpen(true);
    };

    const blurHandler = () => {
        if (value) {
            setSearch(value);
        } else {
            setSearch("");
        }
    };

    return (
        <>
            <div className='search-with-select' ref={searchRef} data-focused={isOpen}>
                <label className='search-with-select-label'>
                    {label}
                    {required && <span className='search-with-select-label-required'> *</span>}
                    <div className='search-with-select-input'>
                        <input
                            type='text'
                            placeholder={value}
                            disabled={disabled}
                            value={search}
                            onChange={(e) => searchInputChangeHandler(e)}
                            onFocus={focusHandler}
                            onBlur={blurHandler}
                            data-disabled={disabled ? "true" : "false"}
                        />
                    </div>
                </label>
                <div className='search-with-select-options' hidden={isOpen ? false : true}>
                    {searchOptions.map((option, index) => {
                        return option === notFoundText ? (
                            <div key={index} onClick={() => setIsOpen(false)}>
                                {option}
                            </div>
                        ) : (
                            <div key={index} onClick={() => setIsOpen(false) || optionsHandler(option)}>
                                {option}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
