import axios from 'axios';

const registrations = require('../app/cwtl-queries/registrations.json');

const activeUsersBetweenDatesQueryTemplate = require('../app/cwtl-queries/active-users-between-dates-query-template.json');
const engagedUsersBetweenDatesQueryTemplate = require('../app/cwtl-queries/engaged-users-between-dates-query-template.json');
const dataCollectionConsentQuery = require('../app/cwtl-queries/data-collection-consent-ratio-query.json');

const lessons = require('../app/cwtl-queries/lessons.json');
// const completeLessonsQuery = require('../app/cwtl-queries/complete-lessons.json');
// const startExercisesQuery = require('../app/cwtl-queries/start-exercises.json');
// const completeExercisesQuery = require('../app/cwtl-queries/complete-exercises.json');

const startExercisesByTypeQuery = require('../app/cwtl-queries/start-exercises-by-type.json');
const comleteExercisesByTypeQuery = require('../app/cwtl-queries/complete-exercises-by-type.json');

const exercisesPlaytime = require('../app/cwtl-queries/exercises-time.json');

class QueryService {
    constructor(baseUrl, username, password) {
        this._baseUrl = baseUrl;
        this._username = username;
        this._password = password;
    }

    _getAuthObj() {
        return {
            username: this._username,
            password: this._password,
        };
    }

    async getMetricData(from, to, metric) {
        const metrics = {
            lessons: lessons,
            startExercisesByTypeQuery: startExercisesByTypeQuery,
            comleteExercisesByTypeQuery: comleteExercisesByTypeQuery,
            exercisesPlaytime: exercisesPlaytime,
            registrations: registrations,
        };

        const queryString = JSON.stringify(metrics[metric])
            .replace(/{{startDate}}/g, from)
            .replace(/{{endDate}}/g, to);

        const response = await axios.get(`${this._baseUrl}/api/statements/aggregate`, {
            auth: this._getAuthObj(),
            headers: {
                'X-Experience-API-Version': '1.0.3',
            },
            params: {
                cache: false,
                pipeline: queryString,
            },
        });

        console.log(metric, response);

        if (!response.data[0]) {
            console.warn(`No data found. Returning '0'.`);
            return 0;
        }

        if (
            metric === 'startExercisesByTypeQuery' ||
            metric === 'comleteExercisesByTypeQuery' ||
            metric === 'exercisesPlaytime' ||
            metric === 'lessons'
        ) {
            return response.data;
        }

        return response.data[0]['Amount'];
    }

    /**
     * Gets users within a date range that own at least one statement.
     * @param {Date} from
     * @param {Date} to
     * @returns {Number}
     */
    async getNumberOfActiveUsersFromTo(from, to) {
        // console.log(`GET active users: ${this._baseUrl}/api/statements/aggregate`);
        // console.log(`From ${from} to ${to}`);
        const queryString = JSON.stringify(activeUsersBetweenDatesQueryTemplate)
            .replace(/{{startDate}}/g, from)
            .replace(/{{endDate}}/g, to);

        const response = await axios.get(`${this._baseUrl}/api/statements/aggregate`, {
            auth: this._getAuthObj(),
            headers: {
                'X-Experience-API-Version': '1.0.3',
            },
            params: {
                cache: false,
                pipeline: queryString,
            },
        });

        // console.log(`GET active users: ${this._baseUrl}/api/statements/aggregate - Response: `, response?.data);

        if (!response.data[0]) {
            console.warn(`No data found. Returning '0'.`);
            return 0;
        }

        return response.data[0]['Amount'];
    }

    /**
     * Gets users within a date range that started at least two bubbles.
     * @param {Date} from
     * @param {Date} to
     * @returns {Number}
     */
    async getNumberOfEngagedUsersFromTo(from, to) {
        // console.log(`GET engaged users: ${this._baseUrl}/api/statements/aggregate`);
        // console.log(`From ${from} to ${to}`);
        const queryString = JSON.stringify(engagedUsersBetweenDatesQueryTemplate)
            .replace(/{{startDate}}/g, from)
            .replace(/{{endDate}}/g, to);

        const response = await axios.get(`${this._baseUrl}/api/statements/aggregate`, {
            auth: this._getAuthObj(),
            headers: {
                'X-Experience-API-Version': '1.0.3',
            },
            params: {
                cache: false,
                pipeline: queryString,
            },
        });

        // console.log(`GET engaged users: ${this._baseUrl}/api/statements/aggregate - Response: `, response?.data);

        if (!response.data[0]) {
            console.warn(`No data found. Returning '0'.`);
            return 0;
        }

        return response.data[0]['Amount'];
    }

    async getDataCollectionConsentMetrics() {
        // console.log(`GET data collection consent ratio: ${this._baseUrl}/api/statements/aggregate`);
        const response = await axios.get(`${this._baseUrl}/api/statements/aggregate`, {
            auth: this._getAuthObj(),
            headers: {
                'X-Experience-API-Version': '1.0.3',
            },
            params: {
                cache: false,
                pipeline: JSON.stringify(dataCollectionConsentQuery),
            },
        });

        // console.log(
        //     `GET data collection consent ratio: ${this._baseUrl}/api/statements/aggregate - Response: `,
        //     response?.data
        // );

        const data = response.data;
        const rejected = data?.find((d) => d['_id'] === 'http://activitystrea.ms/schema/1.0/reject')?.amount;
        const accepted = data?.find((d) => d['_id'] === 'http://activitystrea.ms/schema/1.0/accept')?.amount;
        const acceptedRatio = accepted / (rejected + accepted);

        return { rejected, accepted, acceptedRatio };
    }
}

export default QueryService;
