import './style.scss';

import osvitoriaLogo from '../../assets/img/logos/osvitoria-logo.svg';
import unicefLogo from '../../assets/img/logos/unicef-logo.svg';

export default function UnicefNav() {
    return (
        <div className='unicef-nav'>
            <div className='unicef-nav-logo'>
                <img src={osvitoriaLogo} alt='logo' />
            </div>
            <div className='unicef-nav-logo'>
                <img src={unicefLogo} alt='logo' />
            </div>
        </div>
    );
}
