import { useEffect } from 'react';
import './style.scss';

export default function Input({
    label,
    value,
    handler,
    validation,
    setValidation,
    validationHandler,
    validationMsg,
    setValidationMsg,
    required,
    type
}) {
    useEffect(() => {
        setValidation(true);
    }, []);

    const clearValidation = () => {
        setValidation(true);
        setValidationMsg(undefined);
    };

    return (
        <div className='custom-input'>
            <div className='custom-input-label'>
                {label}
                {required && <span className='custom-input-label-required'> *</span>}
            </div>
            {type === 'phone' && <div className='custom-input-phone-before'>+380</div>}
            <input
                className={type === 'phone' ? 'custom-input-phone' : ''}
                type='text'
                value={value}
                onChange={(e) => handler(e)}
                onFocus={clearValidation}
                onBlur={validationHandler}
                data-valid={validation ? 'true' : 'false'}
            />
            <div className='custom-input-validation'>{validationMsg}</div>
        </div>
    );
}
