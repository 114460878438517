import './style.scss';

export default function CustomInputWithoutValidation({ label, type, required, value, handler }) {
    return (
        <div className='custom-input-without-validation'>
            <div className='custom-input-without-validation-label'>
                {label}
                {required && <span className='custom-input-without-validation-label-required'> *</span>}
            </div>
            <input type={type} value={value} onChange={handler} />
        </div>
    );
}
