import './style.scss';

export default function RadioButtonGroup({
    title,
    name,
    options,
    value,
    handler,
    required,
}) {
    return (
        <div className='radio-button-group'>
            <div className='radio-button-group-title'>
                {title}
                {required && (
                    <span className='radio-button-group-title-required'>
                        {' '}
                        *
                    </span>
                )}
            </div>
            {options.map((option, index) => {
                return (
                    <label key={index} className='radio-button-group-label'>
                        <input
                            className='radio-button-group-input'
                            name={name}
                            type='radio'
                            value={option}
                            checked={value === option}
                            onChange={handler}
                        />
                        <span className='checkmark'></span>
                        {option}
                    </label>
                );
            })}
        </div>
    );
}
