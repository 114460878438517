import React from 'react';
import AuthService from '../../services/auth.service';

import './style.scss';

import userIcon from '../../assets/icons/user.svg';
import PageTitle from '../../components/PageTitle/PageTitle';
import Aside from '../../components/Aside/Aside';

const Profile = () => {
    const ROLES = {
        1: 'Користувач',
        2: 'Модератор',
        3: 'Адміністратор',
    };

    const currentUser = AuthService.getCurrentUser();

    return (
        <div className='main-wrapper'>
            <Aside />

            <main className='container'>
                <div className='profile-page'>
                    <PageTitle pageTitle={'Профіль користувача'} />

                    <div className='profile-page-card'>
                        <div className='profile-page-card-content'>
                            <div className='profile-page-card-content-img'>
                                <img src={userIcon} alt='user icon' />
                            </div>
                            <div className='profile-page-card-content-info'>
                                <div className='profile-page-card-content-info-username'>
                                    <h3>
                                        {currentUser.username} <span>#{currentUser.id}</span>
                                    </h3>
                                </div>
                                <div className='profile-page-card-content-info-email'>{currentUser.email}</div>

                                <div className='profile-page-card-content-info-role'>
                                    {ROLES[currentUser.roles.length]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Profile;
