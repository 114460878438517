import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//components
import Aside from '../../components/Aside/Aside';
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';

//services
import AuthService from '../../services/auth.service';

//styles
import './style.scss';
import { Helmet } from 'react-helmet';

const PAGE_TITLE = 'Система збору даних';

export default function HomePage() {
    const navigate = useNavigate();

    //service states
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (user) {
            setCurrentUser(user);
        } else {
            navigate('/login');
            window.location.reload();
        }
    }, [navigate]);

    useEffect(() => {
        const onPageLoad = () => {
            setIsLoading(false);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE}</title>
            </Helmet>

            {currentUser && (
                <div className='main-wrapper'>
                    <Aside />

                    <main className='container'>
                        <div className='home-page'>
                            <PageTitle pageTitle={PAGE_TITLE} />

                            <div className='home-page-info'>
                                <div className='home-page-desription'>
                                    <p>Вітаємо в системі збору даних Освіторії!</p>
                                    <p>
                                        Тут ви зможете експортувати дані бенефіціарів з форм, розміщених на клієнській
                                        частині систему, а також керувати бенефеціарами вашої роботи вручу.
                                    </p>
                                    <p>Наразі система працює в тестовому режимі та постійно оновлюється.</p>
                                </div>
                                <div className='home-page-updates'>
                                    <h3>Оновлення</h3>
                                    <div>
                                        <div className='home-page-updates-update'>
                                            <h6>09.11.2023</h6>
                                            <p>Інтегровано реєстрації курсів О.Університет</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>05.11.2023</h6>
                                            <p>У формі тренінгів UNICEF можна обирати лише тренінги, призначені конкретному тренеру</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>02.11.2023</h6>
                                            <p>Мінорні виправлення дизайну</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>02.10.2023</h6>
                                            <p>Додано обробник некоректних маршрутів та сторінку 404</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>01.10.2023</h6>
                                            <p>Для шаблонізатора форм додано REST API тренерів та тренінгів</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>28.09.2023</h6>
                                            <p>Додано функціонал управління тренерами та тренінгами</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>20.09.2023</h6>
                                            <p>Додано три рівні доступу для зареєстрованих користувачів</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>17.09.2023</h6>
                                            <p>Додано покращенну аутентифікацію через токен</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>16.09.2023</h6>
                                            <p>Дододано блок користувачів</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>13.09.2023</h6>
                                            <p>Дододано функціонал експорту даних</p>
                                        </div>
                                        <div className='home-page-updates-update'>
                                            <h6>13.09.2023</h6>
                                            <p>Дододано функціонал перегляду реєстрацій на офлайн тренінги UNICEF</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )}
            {isLoading && <Loader />}
        </>
    );
}
