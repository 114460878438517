import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

//components
import UnicefNav from '../../components/UnicefNav/UnicefNav';
import Loader from '../../components/Loader/Loader';
import Input from '../../components/UI/Input/Input';

//api
import API from '../../app/api';

//utilities
import parseDate from '../../features/parseDate';

//styles
import './style.scss';

export default function UnicefRegistrationCheckPage() {
    const navigate = useNavigate();

    //service states
    const [isLoading, setIsLoading] = useState(false);

    //data states
    const [email, setEmail] = useState('');
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [emailValidationMsg, setEmailValidationMsg] = useState(undefined);

    const [userApplications, setUserApplications] = useState(undefined);

    useEffect(() => {
        const reg = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            'i'
        );
        if (!reg.test(email)) {
            setEmailChecked(false);
        } else {
            setEmailChecked(true);
        }
    }, [email]);

    const emailHandler = (e) => setEmail(e.target.value);

    const emailValidationHandler = (e) => {
        const reg = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            'i'
        );
        if (!reg.test(email)) {
            setEmailValidation(false);
            setEmailValidationMsg('Вказано некоректний email.');
        } else {
            setEmailValidation(true);
            setEmailValidationMsg(undefined);
        }
    };

    const checkRegistration = () => {
        setIsLoading(true);
        axios
            .post(API.application.uot.readAllUser, { email })
            .then((response) => {
                setUserApplications(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                navigate('/error', { state: { error: error.response.data.message } });
            });
    };

    return (
        <>
            <Helmet>
                <title>Перевірка реєстрації на тренінги</title>
            </Helmet>
            <UnicefNav />

            <div className='container'>
                <main className='unicef-check-applications-page'>
                    <h1 className='unicef-check-applications-page-title'>Перевірка реєстрацій на тренінги</h1>

                    <div className='form'>
                        <div className='form-row'>
                            <div className='form-col-2'>
                                <Input
                                    label='Email, який ви вказували під час реєстрацій на тренінги'
                                    value={email}
                                    handler={emailHandler}
                                    validationHandler={emailValidationHandler}
                                    validation={emailValidation}
                                    validationMsg={emailValidationMsg}
                                    setValidationMsg={setEmailValidationMsg}
                                    setValidation={setEmailValidation}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='unicef-check-applications-page-button'>
                        <button onClick={checkRegistration} className='submit-button' disabled={!emailChecked}>
                            Перевірити реєстрації
                        </button>
                    </div>

                    <div>
                        {userApplications ? (
                            userApplications.length === 0 ? (
                                <div className='unicef-check-applications-page-alert'>
                                    Реєстрацій за цим email не знайдено, можливо, ви ще не зареєстровані на жоден з
                                    тренінгів або вказали невірний email.
                                </div>
                            ) : (
                                <>
                                    <div className='unicef-check-applications-page-results-title'>
                                        Реєстрації на тренінги для {email}:
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Тренінг</th>
                                                <th>Тренер</th>
                                                <th>Дата тренінгу</th>
                                                <th>Дата та час реєстрації</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userApplications.map((application) => {
                                                return (
                                                    <tr>
                                                        <td>{application.training}</td>
                                                        <td>{application.coach}</td>
                                                        <td>{new Date(application.date).toLocaleDateString('UK')}</td>
                                                        <td>{parseDate(application.createdAt)}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            )
                        ) : (
                            ''
                        )}
                    </div>
                </main>
            </div>
            {isLoading && <Loader />}
        </>
    );
}
