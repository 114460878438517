import { useState, useEffect, useRef } from "react";

import "./style.scss";

import arrowDown from "../../../../assets/icons/arrow-down.svg";

export default function Select({ label, value, options, handler, required, disabled, undefinedOption = true }) {
    const customSelectRef = useRef();

    const [isOptionsShown, setIsOptionsShown] = useState(false);

    useEffect(() => {
        window.addEventListener("click", (e) => {
            const { target } = e;
            if (target instanceof Node && !customSelectRef.current?.contains(target)) {
                setIsOptionsShown(false);
            }
        });
    }, []);

    const showOptions = () => {
        setIsOptionsShown(true);
    };

    const setOption = (option) => {
        setIsOptionsShown(false);
        handler(option);
    };

    return (
        <div className='select' ref={customSelectRef} data-focused={isOptionsShown}>
            <label className='select-label'>
                {label}
                {required && <span className='select-label-required'> *</span>}

                <div className='select-input' onClick={() => !disabled && showOptions()} data-disabled={disabled ? "true" : "false"}>
                    {value === undefined ? "Не вказано" : value}
                    <span className='select-input-arrow' data-reversed={isOptionsShown ? "true" : "false"}>
                        <img src={arrowDown} alt='arrow-down' />
                    </span>
                </div>
            </label>
            <div className='select-options' hidden={!isOptionsShown}>
                {undefinedOption && <div onClick={() => setOption()}>Не вказано</div>}
                {options.map((option, index) => {
                    return (
                        <div key={index} onClick={() => setOption(option)}>
                            {option}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
