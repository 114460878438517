import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

//components
import CustomSelect from "../../components/UI/CustomSelect/CustomSelect";
import Loader from "../../components/Loader/Loader";
import SelectWithSearch from "../../components/UI/SelectWithSearch/SelectWithSearch";
import Input from "../../components/UI/Unicef/Input/Input";
import RadioButtonGroup from "../../components/UI/RadioButtonGroup/RadioButtonGroup";
import CustomCheckBox from "../../components/UI/CustomCheckBox/CustomCheckBox";
import UnicefNav from "../../components/UnicefNav/UnicefNav";
import CustomDateInput from "../../components/UI/CustomDateInput/CustomDateInput";
import CustomInputWithoutValidation from "../../components/UI/CustomInputWithoutValidation/CustomInputWithoutValidation";
import UnicefModal from "../../components/UnicefModal/UnicefModal";
import CustomSelectWithSearch from "../../components/UI/CustomSelectWithSearch/CustomSelectWithSearch";

//api
import API from "../../app/api";

//styles
import "./style.scss";

export default function UnicefFormPage() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [coaches, setCoaches] = useState([]);
    const [coachesData, setCoachesData] = useState({});
    const [coach, setCoach] = useState(undefined);

    const [trainings, setTrainings] = useState([]);
    const [training, setTraining] = useState(undefined);

    const [trainingDate, setTrainingDate] = useState(new Date());

    const [firstName, setFirstName] = useState("");
    const [firstNameValidation, setFirstNameValidation] = useState(false);
    const [firstNameValidationMsg, setFirstNameValidationMsg] = useState(undefined);

    const [lastName, setLastName] = useState("");
    const [lastNameValidation, setLastNameValidation] = useState(false);
    const [lastNameValidationMsg, setLastNameValidationMsg] = useState(undefined);

    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailValidationMsg, setEmailValidationMsg] = useState(undefined);

    const [phone, setPhone] = useState("");
    const [phoneValidation, setPhoneValidation] = useState(false);
    const [phoneValidationMsg, setPhoneValidationMsg] = useState(undefined);

    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(undefined);

    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(undefined);

    const [communities, setCommunities] = useState([]);
    const [community, setCommunity] = useState(undefined);

    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(undefined);

    const [schoolNames, setSchoolNames] = useState([]);
    const [schoolName, setSchoolName] = useState(undefined);
    const [schoolNamesSearch, setSchoolNamesSearch] = useState([]);
    const [schoolNameSearch, setSchoolNameSearch] = useState("");

    const [schoolId, setSchoolId] = useState(undefined);

    const [userSchoolName, setUserSchoolName] = useState("");

    const [primarySchoolCoverage, setPrimarySchoolCoverage] = useState("0");
    const [primarySchoolCoverageValidation, setPrimarySchoolCoverageValidation] = useState(false);
    const [primarySchoolCoverageValidationMsg, setPrimarySchoolCoverageValidationMsg] = useState(undefined);

    const [secondarySchoolCoverage, setSecondarySchoolCoverage] = useState("0");
    const [secondarySchoolCoverageValidation, setSecondarySchoolCoverageValidation] = useState(false);
    const [secondarySchoolCoverageValidationMsg, setSecondarySchoolCoverageValidationMsg] = useState(undefined);

    const [highSchoolCoverage, setHighSchoolCoverage] = useState("0");
    const [highSchoolCoverageValidation, setHighSchoolCoverageValidation] = useState(false);
    const [highSchoolCoverageValidationMsg, setHighSchoolCoverageValidationMsg] = useState(undefined);

    const [gender, setGender] = useState("Жінка");

    const [idp, setIdp] = useState("Ні");

    const [disablement, setDisablement] = useState("Ні");

    const [isReportingAllowed, setIsReportingAllowed] = useState(true);

    const [isCommunicationAllowed, setIsCommunicationAllowed] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(API.coach.readAll)
            .then((response) => {
                let { data } = response;
                setCoaches(data.map((coach) => coach.name));

                let dat = {};
                data.forEach((coach) => (dat[coach.name] = coach.trainings.map((i) => i.name)));

                setCoachesData(dat);
                setIsLoading(false);
            })
            .catch((error) => {
                navigate("/error", { state: { error: error.response.data.message } });
            });
    }, [navigate]);

    useEffect(() => {
        if (coach) {
            setTrainings(coachesData[coach]);
        } else {
            setTrainings([]);
        }
        setTraining(undefined);
    }, [coach, coachesData]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(API.school.readRegions)
            .then((response) => {
                let { data } = response;
                setRegions(
                    data
                        .map((region) => region.region)
                        .sort((a, b) => {
                            return a.localeCompare(b);
                        })
                );
                setIsLoading(false);
            })
            .catch((error) => {
                navigate("/error", { state: { error: error.response.data.message } });
            });
    }, [navigate]);

    useEffect(() => {
        if (region) {
            setIsLoading(true);
            axios
                .post(API.school.readDistrict, {
                    region: region,
                })
                .then((response) => {
                    let { data } = response;
                    setDistricts(data.map((district) => district.district));
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region, navigate]);

    useEffect(() => {
        if (region && district) {
            setIsLoading(true);
            axios
                .post(API.school.readCommunities, {
                    region: region,
                    district: district,
                })
                .then((response) => {
                    let { data } = response;
                    setCommunities(data.map((community) => community.community));
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region, district, navigate]);

    useEffect(() => {
        if (region && district && community) {
            setIsLoading(true);
            axios
                .post(API.school.readCities, {
                    region: region,
                    district: district,
                    community: community,
                })
                .then((response) => {
                    let { data } = response;
                    setCities(data.map((city) => city.city));
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region, district, community, navigate]);

    useEffect(() => {
        if (region && district && community && city) {
            setIsLoading(true);
            axios
                .post(API.school.readSchoolNames, {
                    region: region,
                    district: district,
                    community: community,
                    city: city,
                })
                .then((response) => {
                    let { data } = response;
                    setSchoolNames(data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region, district, community, city, navigate]);

    const clearDistrict = () => {
        setDistrict(undefined);
        setDistricts([]);
    };

    const clearCommunity = () => {
        setCommunity(undefined);
        setCommunities([]);
    };

    const clearCity = () => {
        setCity(undefined);
        setCities([]);
    };

    const clearSchool = () => {
        setSchoolName(undefined);
        setSchoolId(undefined);
        setSchoolNameSearch("");
        setSchoolNames([]);
        setSchoolNamesSearch([]);
    };

    const trainingHandler = (value) => {
        if (value === "undefined") {
            setTraining(undefined);
            return;
        } else {
            setTraining(value);
        }
    };

    const trainingDateHandler = (value) => {
        setTrainingDate(value);
    };

    const regionHandler = (option = undefined) => {
        if (option === undefined) {
            setRegion(undefined);
        } else {
            setRegion(option);
        }
        clearDistrict();
        clearCommunity();
        clearCity();
        clearSchool();
    };

    const districtHandler = (option = undefined) => {
        if (option === undefined) {
            setDistrict(undefined);
        } else {
            setDistrict(option);
        }
        clearCommunity();
        clearCity();
        clearSchool();
    };

    const communityHandler = (option = undefined) => {
        if (option === undefined) {
            setCommunity(undefined);
        } else {
            setCommunity(option);
        }
        clearCity();
        clearSchool();
    };

    const cityHandler = (option = undefined) => {
        if (option === undefined) {
            setCity(undefined);
        } else {
            setCity(option);
        }
        clearSchool();
    };

    const schoolNameHandler = (i) => {
        const value = i.value;
        const id = i.id;
        if (value === "undefined") {
            setSchoolName(undefined);
            setSchoolId(undefined);
            return;
        } else {
            setSchoolName(value);
            setSchoolId(id);

            setSchoolNameSearch(value);
            setSchoolNamesSearch([value]);
            setUserSchoolName("");
        }
    };

    const schoolNameFocusHandler = () => {
        setSchoolNameSearch("");
        setSchoolNamesSearch([...schoolNames, { value: "Мого навчального закладу немає в списку" }]);
    };

    const schoolNameBlurHandler = () => {
        setSchoolNameSearch(schoolName);
        setSchoolNamesSearch([]);
    };

    const searchSchoolNameHandler = (e) => {
        let value = e.target.value;
        setSchoolNameSearch(value);
        let searchQuery = value.toLowerCase();
        let schoolNamesResults = schoolNames.filter((i) => {
            return i.value.toLowerCase().includes(searchQuery);
        });

        if (schoolNamesResults.length !== 0) {
            setSchoolNamesSearch([...schoolNamesResults, { value: "Мого навчального закладу немає в списку" }]);
        } else {
            setSchoolNamesSearch([{ value: "Мого навчального закладу немає в списку" }]);
        }
    };

    const userSchoolNameHandler = (e) => setUserSchoolName(e.target.value);

    const nameValidation = (e, setValidation, setValidationMsg, field, value) => {
        const reg = new RegExp(/^[АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЬьЮюЯя'"`-]+$/, "g");
        if (value.length < 2) {
            setValidation(false);
            setValidationMsg(field + " повинно мати як мінімум дві літери.");
        } else if (!reg.test(value)) {
            setValidation(false);
            setValidationMsg(field + " повинно мати тільки українські літери та не містити пробілів.");
        } else {
            setValidation(true);
            setValidationMsg(undefined);
        }
    };

    const firstNameHandler = (e) => setFirstName(e.target.value);

    const firstNameValidationHandler = (e) => {
        nameValidation(e, setFirstNameValidation, setFirstNameValidationMsg, "Iм'я", firstName);
    };

    const lastNameHandler = (e) => setLastName(e.target.value);

    const lastNameValidationHandler = (e) => {
        nameValidation(e, setLastNameValidation, setLastNameValidationMsg, "Прізвище", lastName);
    };

    const emailHandler = (e) => setEmail(e.target.value);

    const emailValidationHandler = (e) => {
        const reg = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            "i"
        );
        if (!reg.test(email)) {
            setEmailValidation(false);
            setEmailValidationMsg("Вказано некоректний email.");
        } else {
            setEmailValidation(true);
            setEmailValidationMsg(undefined);
        }
    };

    const phoneHandler = (e) => setPhone(e.target.value);

    const phoneValidationHandler = () => {
        const reg = new RegExp(/^\d+$/);
        if (!reg.test(phone)) {
            setPhoneValidation(false);
            setPhoneValidationMsg("Телефон повинен містити тільки цифри.");
        } else if (phone.length !== 9) {
            setPhoneValidation(false);
            setPhoneValidationMsg("Телефон повинен містити 9 цифр.");
        } else {
            setPhoneValidation(true);
            setPhoneValidationMsg(undefined);
        }
    };

    const primarySchoolCoverageHandler = (e) => {
        const { value } = e.target;

        const reg = new RegExp(/^\d+$/);
        if (reg.test(value) || value === "") {
            return setPrimarySchoolCoverage(value);
        }

        return;
    };

    const primarySchoolCoverageValidationHandler = () => {
        const reg = new RegExp(/^\d+$/);
        if (!reg.test(primarySchoolCoverage)) {
            setPrimarySchoolCoverageValidation(false);
            setPrimarySchoolCoverageValidationMsg("Поле повинно містити ціле ціле невід'ємне число.");
        } else if (primarySchoolCoverage.length > 5) {
            setPrimarySchoolCoverageValidation(false);
            setPrimarySchoolCoverageValidationMsg("Занадто велике число.");
        } else {
            setPrimarySchoolCoverageValidation(true);
            setPrimarySchoolCoverageValidationMsg(undefined);
        }
    };

    const secondarySchoolCoverageHandler = (e) => {
        const { value } = e.target;

        const reg = new RegExp(/^\d+$/);
        if (reg.test(value) || value === "") {
            return setSecondarySchoolCoverage(value);
        }

        return;
    };

    const secondarySchoolCoverageValidationHandler = () => {
        const reg = new RegExp(/^\d+$/);
        if (!reg.test(secondarySchoolCoverage)) {
            setSecondarySchoolCoverageValidation(false);
            setSecondarySchoolCoverageValidationMsg("Поле повинно містити ціле ціле невід'ємне число.");
        } else if (secondarySchoolCoverage.length > 5) {
            setSecondarySchoolCoverageValidation(false);
            setSecondarySchoolCoverageValidationMsg("Занадто велике число.");
        } else {
            setSecondarySchoolCoverageValidation(true);
            setSecondarySchoolCoverageValidationMsg(undefined);
        }
    };

    const highSchoolCoverageHandler = (e) => {
        const { value } = e.target;

        const reg = new RegExp(/^\d+$/);
        if (reg.test(value) || value === "") {
            return setHighSchoolCoverage(value);
        }

        return;
    };

    const highSchoolCoverageValidationHandler = () => {
        const reg = new RegExp(/^\d+$/);
        if (!reg.test(highSchoolCoverage)) {
            setHighSchoolCoverageValidation(false);
            setHighSchoolCoverageValidationMsg("Поле повинно містити ціле ціле невід'ємне число.");
        } else if (highSchoolCoverage.length > 5) {
            setHighSchoolCoverageValidation(false);
            setHighSchoolCoverageValidationMsg("Занадто велике число.");
        } else {
            setHighSchoolCoverageValidation(true);
            setHighSchoolCoverageValidationMsg(undefined);
        }
    };

    const genderHandler = (e) => setGender(e.target.value);

    const idpHandler = (e) => setIdp(e.target.value);

    const disablementHandler = (e) => setDisablement(e.target.value);

    const isReportingAllowedHandler = (e) => setIsReportingAllowed((prev) => !prev);

    const isCommunicationAllowedHandler = (e) => setIsCommunicationAllowed((prev) => !prev);

    const sendForm = () => {
        if (
            coach &&
            training &&
            region &&
            district &&
            community &&
            city &&
            (userSchoolName || schoolName) &&
            isReportingAllowed &&
            String(trainingDate) &&
            firstName &&
            lastName &&
            email &&
            phone &&
            primarySchoolCoverage &&
            secondarySchoolCoverage &&
            highSchoolCoverage
        ) {
            setIsModalOpen((prev) => !prev);
            axios
                .post(API.application.uot.create, {
                    coach: coach,
                    training: training,
                    region: region,
                    district: district,
                    community: community,
                    city: city,
                    school_id: isNaN(Number(schoolId)) ? null : Number(schoolId),
                    name: userSchoolName || schoolName,
                    date: String(trainingDate),
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: "+380" + phone,
                    primary_school_coverage: primarySchoolCoverage,
                    secondary_school_coverage: secondarySchoolCoverage,
                    high_school_coverage: highSchoolCoverage,
                    gender: gender,
                    idp: idp,
                    disablement: disablement,
                    communication: isCommunicationAllowed,
                })
                .then((response) => {
                    setIsModalOpen(true);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    };

    const modalHandler = () => {
        window.location.reload();
    };

    return (
        <>
            <Helmet>
                <title>Реєстрація на тренінги</title>
            </Helmet>
            <UnicefNav />

            <div className='container'>
                <main className='unicef-form-page'>
                    <h1 className='unicef-form-page-title'>Форма реєстрації на тренінги</h1>
                    <div className='unicef-form-page-alert'>
                        <div className='unicef-form-page-alert-title'>
                            <div>!</div>
                            <h2>Повідомлення про збір та обробку даних</h2>
                        </div>
                        <div className='unicef-form-page-alert-content'>
                            Просимо вас надавати коректну та актуальну інформацію при заповненні форми. Ця інформація допоможе нам проаналізувати стан
                            освіти в України з урахуванням регіональних та гендерних аспектів. Також дані будуть узагальнено використані для
                            звітування у межах комунікації про проєкт. Обробка даних складається зі знеособлених даних, тож не несе ризику порушення
                            прав і свобод пов'язаних із приватністю. Ми не передаватимемо ваші контактні дані третім сторонам.
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-col-2'>
                            <CustomSelectWithSearch
                                label='Тренер'
                                value={coach}
                                setValue={setCoach}
                                options={coaches}
                                required={true}
                                notFoundText='Тренера не знайдено'
                            />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-col-2'>
                            <CustomSelect
                                label={"Оберіть тренінг"}
                                value={training}
                                options={trainings}
                                handler={trainingHandler}
                                required={true}
                                disabled={coach ? false : true}
                            />
                        </div>
                        <div className='form-col-2'>
                            <CustomDateInput label={"Оберіть дату тренінга"} value={trainingDate} handler={trainingDateHandler} required={true} />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-col-2'>
                            <Input
                                label={"Ім'я"}
                                value={firstName}
                                handler={firstNameHandler}
                                validationHandler={firstNameValidationHandler}
                                validation={firstNameValidation}
                                validationMsg={firstNameValidationMsg}
                                setValidationMsg={setFirstNameValidationMsg}
                                setValidation={setFirstNameValidation}
                                required={true}
                            />
                        </div>
                        <div className='form-col-2'>
                            <Input
                                label={"Прізвище"}
                                value={lastName}
                                handler={lastNameHandler}
                                validationHandler={lastNameValidationHandler}
                                validation={lastNameValidation}
                                validationMsg={lastNameValidationMsg}
                                setValidationMsg={setLastNameValidationMsg}
                                setValidation={setLastNameValidation}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-col-2'>
                            <Input
                                label={"Email"}
                                value={email}
                                handler={emailHandler}
                                validationHandler={emailValidationHandler}
                                validation={emailValidation}
                                validationMsg={emailValidationMsg}
                                setValidationMsg={setEmailValidationMsg}
                                setValidation={setEmailValidation}
                                required={true}
                            />
                        </div>
                        <div className='form-col-2'>
                            <Input
                                label={"Телефон"}
                                value={phone}
                                handler={phoneHandler}
                                validationHandler={phoneValidationHandler}
                                validation={phoneValidation}
                                validationMsg={phoneValidationMsg}
                                setValidationMsg={setPhoneValidationMsg}
                                setValidation={setPhoneValidation}
                                required={true}
                                type={"phone"}
                            />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-col-2'>
                            <CustomSelect
                                label={"Область"}
                                value={region}
                                options={regions}
                                handler={regionHandler}
                                required={true}
                                disabled={false}
                            />
                        </div>

                        <div className='form-col-2'>
                            <CustomSelect
                                label={"Район"}
                                value={district}
                                options={districts}
                                handler={districtHandler}
                                required={true}
                                disabled={region ? false : true}
                            />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-col-2'>
                            <CustomSelect
                                label={"Громада"}
                                value={community}
                                options={communities}
                                handler={communityHandler}
                                required={true}
                                disabled={region && district ? false : true}
                            />
                        </div>

                        <div className='form-col-2'>
                            <CustomSelect
                                label={"Населений пункт"}
                                value={city}
                                options={cities}
                                handler={cityHandler}
                                required={true}
                                disabled={region && district && community ? false : true}
                            />
                        </div>
                    </div>

                    <SelectWithSearch
                        label={"Назва навчального закладу"}
                        value={schoolName}
                        search={schoolNameSearch}
                        searchHandler={searchSchoolNameHandler}
                        options={schoolNamesSearch}
                        optionsHandler={schoolNameHandler}
                        focusHandler={schoolNameFocusHandler}
                        blurHandler={schoolNameBlurHandler}
                        required={true}
                        disabled={region && district && community && city ? false : true}
                    />

                    {schoolName === "Мого навчального закладу немає в списку" && (
                        <div className='form-row'>
                            <div className='form-col-2'>
                                <CustomInputWithoutValidation
                                    label={"Уведіть свій навчальний заклад вручну"}
                                    type={"text"}
                                    required={true}
                                    value={userSchoolName}
                                    handler={userSchoolNameHandler}
                                />
                            </div>
                        </div>
                    )}

                    <div className='form-row'>
                        <div className='form-line'>
                            <Input
                                label='Кількість ваших учнів у початковій школі'
                                value={primarySchoolCoverage}
                                handler={primarySchoolCoverageHandler}
                                validationHandler={primarySchoolCoverageValidationHandler}
                                validation={primarySchoolCoverageValidation}
                                validationMsg={primarySchoolCoverageValidationMsg}
                                setValidationMsg={setPrimarySchoolCoverageValidationMsg}
                                setValidation={setPrimarySchoolCoverageValidation}
                                required={true}
                            />

                            <Input
                                label='Кількість ваших учнів у середній школі'
                                value={secondarySchoolCoverage}
                                handler={secondarySchoolCoverageHandler}
                                validationHandler={secondarySchoolCoverageValidationHandler}
                                validation={secondarySchoolCoverageValidation}
                                validationMsg={secondarySchoolCoverageValidationMsg}
                                setValidationMsg={setSecondarySchoolCoverageValidationMsg}
                                setValidation={setSecondarySchoolCoverageValidation}
                                required={true}
                            />

                            <Input
                                label='Кількість ваших учнів у старшій школі'
                                value={highSchoolCoverage}
                                handler={highSchoolCoverageHandler}
                                validationHandler={highSchoolCoverageValidationHandler}
                                validation={highSchoolCoverageValidation}
                                validationMsg={highSchoolCoverageValidationMsg}
                                setValidationMsg={setHighSchoolCoverageValidationMsg}
                                setValidation={setHighSchoolCoverageValidation}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-col-2'>
                            <RadioButtonGroup
                                title={"Стать"}
                                name={"gender"}
                                options={["Жінка", "Чоловік"]}
                                value={gender}
                                handler={genderHandler}
                                required={true}
                            />
                        </div>
                        <div className='form-col-2'>
                            <RadioButtonGroup
                                title={"Чи є у вас статус ВПО?"}
                                name={"idp"}
                                options={["Ні", "Так"]}
                                value={idp}
                                handler={idpHandler}
                                required={true}
                            />
                        </div>
                        <div className='form-col-2'>
                            <RadioButtonGroup
                                title='Чи є у вас статус особи з інвалідністю?'
                                name='disablement'
                                options={["Ні", "Так"]}
                                value={disablement}
                                handler={disablementHandler}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className='form-row'>
                        <CustomCheckBox
                            label={
                                "Я погоджуюся на використання моїх даних для узагальненої звітності та комунікації щодо результатів тренінгу у межах проєкту."
                            }
                            value={isReportingAllowed}
                            handler={isReportingAllowedHandler}
                        />
                    </div>

                    <div className='form-row'>
                        <CustomCheckBox
                            label={"Я погоджуюся отримувати email та телефонні розсилки з анонсами освітніх подій, тренінгів, курсів тощо."}
                            value={isCommunicationAllowed}
                            handler={isCommunicationAllowedHandler}
                        />
                    </div>

                    <div className='partners'>
                        Навчальні тренінги реалізуються за підтримки Представництва Дитячого фонду ООН (ЮНІСЕФ) в Україні, та за кошти Глобального
                        партнерства в галузі освіти (GPE) і Уряду Японії.
                    </div>

                    <div className='unicef-form-page-button'>
                        <button
                            onClick={sendForm}
                            className='submit-button'
                            disabled={
                                coach &&
                                training &&
                                region &&
                                district &&
                                community &&
                                city &&
                                (userSchoolName || schoolName) &&
                                isReportingAllowed &&
                                String(trainingDate) &&
                                firstName &&
                                lastName &&
                                email &&
                                phone &&
                                primarySchoolCoverage &&
                                secondarySchoolCoverage &&
                                highSchoolCoverage
                                    ? false
                                    : true
                            }
                        >
                            Надіслати
                        </button>
                    </div>

                    <div className='check'>
                        Перевірити свої реєстрації на тренінги ви можете <Link to='/trainings/unicef/check'>за покликанням</Link>.
                    </div>

                    <div className='support'>
                        Виникли питання чи труднощі під час заповнення форми? Напишіть лист на електронну адресу{" "}
                        <a href='mailto:cto@osvitoria.org' target='_blank' rel='noreferrer'>
                            cto@osvitoria.org
                        </a>
                        , і ми обов'язково допоможемо її вирішити.
                    </div>
                </main>
            </div>
            {isLoading && <Loader />}
            {isModalOpen && <UnicefModal handler={modalHandler} />}
        </>
    );
}
