import './style.scss';

export default function Modal({ title, message, handler, buttonText, closeHandler }) {
    return (
        <div className='modal'>
            <div className='modal-window'>
                <button className='modal-window-close-button' onClick={closeHandler}>x</button>
                <h2>{title}</h2>
                <p>{message}</p>
                <button className='modal-window-submit-button' onClick={handler}>{buttonText}</button>
            </div>
        </div>
    );
}
