import './style.scss';

export default function UnicefModal({handler}) {
    return (
        <div className='unicef-modal'>
            <div className='unicef-modal-window'>
            <h2>
                    Дякуємо, ви успішно зареєструвались на тренінг!
                </h2>
                <p>
                    На вашу електронну адресу було відправлено лист з підтвердженням реєстрації.
                </p>
                <button onClick={handler}>
                    Закрити
                </button>
            </div>
        </div>
    );
}
