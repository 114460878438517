import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

//form validation
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';

//services
import AuthService from '../../services/auth.service';

//styles
import './style.scss';
import Loader from '../../components/Loader/Loader';

const required = (value) => {
    if (!value) {
        return (
            <div className='login-card-form-group-alert' role='alert'>
                Це поле обов'язкове!
            </div>
        );
    }
};

const Login = () => {
    let navigate = useNavigate();

    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setMessage('');
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            AuthService.login(username, password).then(
                () => {
                    navigate('/');
                    window.location.reload();
                },
                (error) => {
                    const resMessage =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    };

    return (
        <>
        <main className='container'>
            <div className='login'>
                <div className='login-card'>
                    <div>
                        <h1 className='login-card-title'>Вхід в систему</h1>
                    </div>

                    <Form onSubmit={handleLogin} ref={form}>
                        <div className='login-card-form-group'>
                            <label className='login-card-form-group-label' htmlFor='username'>
                                Email<span>*</span>
                            </label>
                            <Input
                                type='text'
                                className='login-card-form-group-input'
                                name='username'
                                value={username}
                                onChange={onChangeUsername}
                                validations={[required]}
                            />
                        </div>

                        <div className='login-card-form-group'>
                            <label className='login-card-form-group-label' htmlFor='password'>
                                Пароль<span>*</span>
                            </label>
                            <Input
                                type='password'
                                className='login-card-form-group-input'
                                name='password'
                                value={password}
                                onChange={onChangePassword}
                                validations={[required]}
                            />
                        </div>

                        <div className='login-card-form-group'>
                            <CheckButton className='login-card-form-group-button' disabled={loading} ref={checkBtn}>
                                {loading && <span className='spinner-border spinner-border-sm'></span>}
                                <span>Увійти</span>
                            </CheckButton>
                        </div>

                        {message && (
                            <div className='form-group'>
                                <div className='login-card-form-group-alert' role='alert'>
                                    {message}
                                </div>
                            </div>
                        )}
                        {/* <CheckButton style={{ display: 'none' }} ref={checkBtn} /> */}
                    </Form>
                </div>
            </div>
        </main>
        {loading && <Loader />}
        </>
    );
};

export default Login;
